/* latin */
@font-face {
  font-family: '__Lusitana_89d4b4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c556ae4be4c9cfa8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: '__Lusitana_89d4b4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ebd7dc65a6ba3e83-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lusitana_Fallback_89d4b4';src: local("Times New Roman");ascent-override: 92.03%;descent-override: 32.83%;line-gap-override: 0.00%;size-adjust: 103.88%
}.__className_89d4b4 {font-family: '__Lusitana_89d4b4', '__Lusitana_Fallback_89d4b4';font-style: normal
}

